import React, { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, HeaderText, PageSection, Select, SwiperItem } from '@/components/_root';
// import { CarouselItem } from './styled';
import BlockContent from '@/components/block-content';
// import {
//   formatLocationOptions,
//   formatTeamOptions,
//   optionNameToValue,
// } from '@/utils/helper/format-options';
import { IVacancy } from '@/utils/interface';
import { Link } from 'gatsby';
// import CarouselOrGrid from '@/components/carousel-or-grid';
import SectionTitle from '../section-title';
import { useViewport } from '../_hook';
interface Props {
  title: { outlined: string; solid: string; isSolidFirst?: boolean };
  description: ReactNode;
  teams: any[];
  locations: any[];
  vacancies: IVacancy[];
}

const CurrentVacanciesSection = ({
  title,
  description,
  teams,
  locations,
  vacancies,
}: Props): JSX.Element => {
  const { container } = useViewport();
  const [selectedTeam, setSelectedTeam] = useState({
    id: 1,
    name: 'Select a team',
    value: '',
  });
  const [selectedLocation, setSelectedLocation] = useState({
    id: 1,
    name: 'select a Location',
    value: '',
  });

  return (
    <>
      <Helmet>
        <script src="https://riseatseven.bamboohr.com/js/embed.js" type="text/javascript" async defer></script>
      </Helmet>
      <PageSection mode="dark">
        <Container>
          <SectionTitle title={title} mode="dark" />
          <BlockContent
            blocks={description}
            className="flex flex-col gap-3 mt-4 text-white md:mt-6 lg:mt-8 xl:mt-10"
            />
          <div className="flex flex-col items-center gap-8 my-12 md:my-16 lg:my-20 xl:my-24 sm:flex-row">
            <HeaderText size="h3" mode="dark" className="hidden sm:inline-block">
              FIND YOUR DREAM JOB
            </HeaderText>
          </div>
        </Container>
        <Container>
          <div className="bamboohr">
            <div id="BambooHR" data-domain="riseatseven.bamboohr.com" data-version="1.0.0" data-departmentId=""></div>
          </div>
        </Container>
      </PageSection>
    </>
  );
};

export default CurrentVacanciesSection;
