import { IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';
import { Image } from '@/components/_root';
import HoverList from '@/components/hover-list';

interface Props {
  children: ReactNode;
}

interface ISocialLink {
  icon: ReactNode;
  link: any;
  type: string;
}

export const OverlayContainer = ({ children }: Props): JSX.Element => {
  return (
    <div className="bg-no-repeat w-full bg-center relative hidden sm:block">
      <StaticImage
        src="../../../assets/img/our-social-channel-overlay.png"
        alt=""
        className="w-full z-0 h-full"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
      <div className="relative z-10 pt-10">{children}</div>
    </div>
  );
};

export const SocialMediaContainer = ({ children }: Props): JSX.Element => {
  return (
    <HoverList className="flex gap-5 lg:gap-12 items-center justify-center">{children}</HoverList>
  );
};

interface ChannelCarouselItemProps {
  thumbnailSrc: string | IGatsbyImageData;
  channelType?: string | 'facebook' | 'twitter' | 'youtube' | 'linkedin' | 'instagram' | 'tiktok';
  socialLinks?: ISocialLink[];
}

export const ChannelCorouselItem = ({
  socialLinks,
  channelType,
  thumbnailSrc,
}: ChannelCarouselItemProps): any => {
  const IconByType = socialLinks?.find(
    (socialLink) => socialLink.type.toLowerCase() === channelType?.toLowerCase(),
  )?.icon;

  return (
    <div className="relative pointer-events-none px-2">
      <span className="absolute h-8 w-8 z-20" style={{ right: '16%', bottom: '10%' }}>
        {IconByType}
      </span>
      <Image src={thumbnailSrc} />
    </div>
  );
};
