import React from 'react';
import BannerDescription, { BannerDescriptionProps } from '@/components/banner-description';
import { PageSection } from '@/components/_root';
import BlockContent from '@/components/block-content';
import SectionTitle from '@/components/section-title';

const OurMissionSection = (props: BannerDescriptionProps): JSX.Element => {
  const title = <SectionTitle title={props.sectionTitle} />;
  const description = <BlockContent blocks={props?.body} />;
  return (
    <PageSection>
      <BannerDescription {...props} body={description} sectionTitle={title} />
    </PageSection>
  );
};

export default OurMissionSection;
