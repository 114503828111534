import React, { ReactNode } from 'react';
import { Container, HeaderText, Image } from '@/components/_root';
import { ContentWrapper } from './styles';

export interface BannerDescriptionProps {
  sectionTitle: string | ReactNode | any;
  body: ReactNode;
  bannerImageUrl: string;
  bannerPosition?: 'left' | 'right';
  floating?: boolean;
  className?: string;
  footerActionButton?: ReactNode;
  imageOrderFirst?: boolean;
}

const BannerDescription = ({
  sectionTitle,
  body,
  bannerImageUrl,
  bannerPosition = 'left',
  floating = false,
  className = '',
  footerActionButton,
  imageOrderFirst = false,
}: BannerDescriptionProps): JSX.Element => {
  let imgClasses = className;
  if (floating) imgClasses = imgClasses + ` md:order-last -mt-8`;
  if (!floating && bannerPosition && !imageOrderFirst) imgClasses = imgClasses + ` order-last`;
  if (!floating && imageOrderFirst) imgClasses = imgClasses + ` order-last md:order-none`;
  if (imageOrderFirst) imgClasses = imgClasses + ` order-none`;

  return (
    <Container>
      <ContentWrapper floating={floating}>
        <div className={imgClasses}>
          <Image src={bannerImageUrl} alt="" className={`rounded-md`} objectFit="cover" />
        </div>
        <div className={floating ? `py-0 md:py-24 lg:py-32 pb-16 md:pb-0` : ``}>
          {typeof sectionTitle === 'string' ? (
            <HeaderText>{sectionTitle}</HeaderText>
          ) : (
            sectionTitle
          )}
          <div className="flex flex-col basis-1/2 gap-5 mt-9 contentBody">{body}</div>
          {!!footerActionButton && footerActionButton}
        </div>
      </ContentWrapper>
    </Container>
  );
};

export default BannerDescription;
