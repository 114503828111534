/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Container, PageSection } from '@/components/_root';
import { ServicesItem } from '../services/styled';
import { BenefitItemsWrapper } from './styled';
import SectionTitle from '../section-title';

interface IBenefit {
  title: string;
  description: string;
}

interface Props {
  title: { outlined: string; solid: string; isOutlinedFirst?: boolean };
  benefits: IBenefit[];
}

const OurBenefitsSection = ({ title, benefits }: Props): JSX.Element => {
  return (
    <PageSection mode="dark">
      <Container>
        <SectionTitle title={title} mode="dark" />

        <OurBenefitsSection.Item benefits={benefits} />
      </Container>
    </PageSection>
  );
};

OurBenefitsSection.Item = ({ benefits }: { benefits: IBenefit[] }): JSX.Element => {
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const lastIndex = benefits.length - 1;
  return (
    <BenefitItemsWrapper>
      {benefits.map((benefit, index) => (
        <ServicesItem
          key={index}
          noOdd
          hoverElementFullWidth
          index={index}
          serviceName={benefit.title}
          serviceDescription={benefit.description}
          lastIndex={lastIndex}
          hoverIndex={-1 || hoverIndex}
          onHover={(i: number): void => setHoverIndex(i)}
          defaultCursor
        />
      ))}
    </BenefitItemsWrapper>
  );
};
export default OurBenefitsSection;
