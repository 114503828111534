import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  floating?: boolean;
}

export const ContentWrapper = ({ children, floating }: Props): JSX.Element => (
  <div
    className={`grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-12 lg:gap-14 xl:gap-16 ${
      !floating ? `place-items-center` : ``
    }`}
  >
    {children}
  </div>
);
