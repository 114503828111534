import { UpArrowIcon, DownArrowIcon } from '@/components/_icons';
import React from 'react';

interface Props {
  title: string;
  description: string;
  isOpen?: boolean;
  setOpenedAccordion: (accordionIndex: number) => void;
  id: number;
}

const Accordion2 = ({ title, description, isOpen, setOpenedAccordion, id }: Props): JSX.Element => {
  const titleStyle = 'text-lg';
  const descriptionStyle = 'text-base max-w-4xl pb-6';

  return (
    <div>
      <div className="relative ">
        <button
          type="button"
          className="w-full py-4 text-left flex justify-between items-center"
          onClick={(): void => setOpenedAccordion(id)}
        >
          <h3 className={titleStyle}>{title}</h3>
          <span>{isOpen ? <UpArrowIcon /> : <DownArrowIcon />}</span>
        </button>
        <div
          className={`relative overflow-hidden transition-all duration-700 ${
            isOpen ? 'max-h-96' : 'max-h-0'
          }`}
        >
          <div className={descriptionStyle}>{description}</div>
        </div>
        <Accordion2.Divider />
      </div>
    </div>
  );
};

Accordion2.Divider = (): JSX.Element => {
  return <div className="w-full h-0 border-b-2 border-black"></div>;
};

export default Accordion2;
