import React from 'react';
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  YoutubeIcon,
  TiktokIcon,
} from '@/components/_icons';
import { Carousel, HeaderText, PageSection } from '@/components/_root';
import { ChannelCorouselItem, OverlayContainer, SocialMediaContainer } from './styled';
import { ISocialMedia } from '@/utils/interface';
import { IGatsbyImageData } from 'gatsby-plugin-image';
interface Props {
  title: string;
  socialMedia: ISocialMedia;
  channels: {
    channel_type: string;
    channel_image: string | IGatsbyImageData;
  }[];
}

const responsive = {
  lg: {
    breakpoint: { max: 3000, min: 768 },
    items: 5,
  },
  md: {
    breakpoint: { max: 768, min: 640 },
    items: 3,
  },
};

const OurSocialChannelSection = ({ title, socialMedia, channels }: Props): JSX.Element => {
  const socialLinks = [
    { icon: FacebookIcon, link: socialMedia?.facebook, type: 'facebook' },
    { icon: TwitterIcon, link: socialMedia?.twitter, type: 'twitter' },
    {
      icon: InstagramIcon,
      link: socialMedia?.instagram,
      type: 'instagram',
    },
    { icon: LinkedinIcon, link: socialMedia?.linkedin, type: 'linkedin' },
    { icon: YoutubeIcon, link: socialMedia?.youtube, type: 'youtube' },
    { icon: TiktokIcon, link: socialMedia?.tiktok, type: 'tiktok' },
  ];

  return (
    <OverlayContainer>
      <PageSection>
        <div className="flex justify-center flex-col md:flex-row items-center">
          <HeaderText mode="dark" size="h2">
            {title}
          </HeaderText>
        </div>
        <Carousel
          responsive={responsive}
          arrows={false}
          additionalTransfrom={0}
          centerMode={true}
          swipeable
          draggable
          infinite
          keyBoardControl
          autoPlay
          ssr
          sliderClass={`relative lg:left-10 cursor-pointer`}
          className="my-24"
        >
          {channels.map((channel, index) => (
            <ChannelCorouselItem
              key={index}
              thumbnailSrc={channel.channel_image}
              channelType={channel.channel_type}
              socialLinks={socialLinks}
            />
          ))}
        </Carousel>

        <SocialMediaContainer>
          {socialLinks.map((socialLink, index) => (
            <a href={socialLink.link} key={index}>
              {<socialLink.icon size={48} />}
            </a>
          ))}
        </SocialMediaContainer>
      </PageSection>
    </OverlayContainer>
  );
};

export default OurSocialChannelSection;
