import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const BenefitItemsWrapper = ({ children }: Props): JSX.Element => {
  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 text-white gap-9 mt-20">
      {children}
    </div>
  );
};
