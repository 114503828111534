import React, { ReactNode } from 'react';
import { Container, HeaderText, PageSection, Image } from '@/components/_root';
import { OverlayContainer } from './styled';

interface Item {
  title: string;
  icon: string;
  description: ReactNode;
}

interface Props {
  title: string;
  items: Item[];
}

const OurValuesSection = ({ title, items }: Props): JSX.Element => {
  return (
    <OverlayContainer>
      <PageSection paddingAmount="small">
        <Container>
          <HeaderText size="h2" mode="dark" className="text-center">
            {title}
          </HeaderText>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-11 mt-14 md:mt-32">
            {items?.map((item, index) => (
              <OurValuesSection.Item key={index} item={item} />
            ))}
          </div>
        </Container>
      </PageSection>
    </OverlayContainer>
  );
};

OurValuesSection.Item = ({ item: { title, description, icon } }: { item: Item }): JSX.Element => {
  const splitted = title.split(' ');
  const half = title.split(' ').length / 2;
  return (
    <div className="text-white md:text-center ">
      <div className="flex md:flex-col items-center justify-between ">
        <Image src={icon} alt="" className="order-2 md:order-1" />
        <div className="text-white mt-6 mb-8 font-dm-sans order-1">
          <h3>{splitted.slice(0, half).join(' ')}</h3>
          <h3>{splitted.slice(half, splitted.length).join(' ')}</h3>
        </div>
      </div>
      <div className="flex flex-col gap-6">{description}</div>
    </div>
  );
};

export default OurValuesSection;
