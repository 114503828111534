import React, { useState } from 'react';
import { Container, PageSection } from '@/components/_root';
// import Accordion from '../_root/accordion';
import Accordion2 from '../_root/accordion-2';
import SectionTitle from '../section-title';

interface IAccordion {
  question: string;
  answer: string;
}

interface Props {
  qAndAAccordionData: IAccordion[];
  title: { solid: string; outlined: string; isOutlinedFirst?: boolean };
}

const BriefQAndASection = ({ qAndAAccordionData, title }: Props): JSX.Element => {
  const [openedAccordion, setOpenedAccordion] = useState(0);
  return (
    <PageSection>
      <Container>
        <SectionTitle title={title} />
        <div className="mt-20">
          {qAndAAccordionData.map((accordion, index) => (
            <Accordion2
              title={accordion.question}
              description={accordion.answer}
              key={index}
              setOpenedAccordion={setOpenedAccordion}
              id={index}
              isOpen={openedAccordion === index}
            />
          ))}
        </div>
      </Container>
    </PageSection>
  );
};

export default BriefQAndASection;
