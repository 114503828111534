import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import PageBanner from '@/components/teams-page-banner';
import OurValuesSection from '@/components/our-values-section';
import SectionSummary from '@/components/section-summary';
import OurMissionSection from '@/components/our-mission-section';
import OurBenefitsSection from '@/components/our-benefits-section';
import BriefQAndASection from '@/components/brief-q-and-a-section';
import OurSocialChannelSection from '@/components/our-social-channels';
import CurrentVacanciesSection from '@/components/current-vacancies-section';
import { getTitle } from '@/utils/helper/getTitle';

const CareersPage = ({
	data,
	location,
	pageContext,
}: PageProps<any>): JSX.Element => {
	const {
		// @ts-ignore
		breadcrumb: { crumbs },
	} = pageContext;

	const vacancies = data?.sanityCareersVacancies?.bamboo_vacancies;
	//const vacancies = data?.sanityCareersVacancies?.vacancies;

	const vacancy_locations: Array<string> = [];
	const vacancy_teams: Array<string> = [];
	vacancies.forEach((vacancy: Array<object>) => {
		if (!vacancy_locations.includes(vacancy.vacancy_location.vacancy_location)) {
			vacancy_locations.push(vacancy.vacancy_location.vacancy_location)
		}
		if (!vacancy_teams.includes(vacancy.vacancy_teams.team_name)) {
			vacancy_teams.push(vacancy.vacancy_teams.team_name)
		}
	})

	/** Filter out vacancy locations without any open jobs */
	const locations = data?.allSanityVacancyLocations?.edges.filter((location: object) => {
		if (vacancy_locations.includes(location.node.vacancy_location)) {
			return location;
		}
	})

	/** Filter out vacancy teams without any open jobs */
	const teams = data?.allSanityVacancyTeams?.edges.filter((team: object) => {
		if (vacancy_teams.includes(team.node.team_name)) {
			return team;
		}
	})

	return (
		<Layout location={location} crumbs={crumbs}>
			<Seo
				title={
					data?.sanityCareersPageMetadata?.careers_page_metadata
						?.pageMetaName || 'Career'
				}
				description={
					data?.sanityCareersPageMetadata?.careers_page_metadata
						?.pageMetaDescription
				}
				pageMetadata={
					data?.sanityCareersPageMetadata?.careers_page_metadata?.metadata
				}
				canonical={
					data?.sanityCareersPageMetadata?.careers_page_metadata?.canonical
				}
			/>
			<PageBanner
				backgroundImageUrl={data?.sanityCareersBanner?.background_image}
				title={{ solid: data?.sanityCareersBanner?.title }}
				headerTextWidth='md:w-11/12'
			/>
			<span className='relative bottom-14 lg:bottom-0'>
				<SectionSummary
					imageUrl={data?.sanityCareersSummary?.banner_image}
					title={getTitle(data?.sanityCareersSummary)}
					description={data?.sanityCareersSummary?._rawDescription}
					reverse
				/>
			</span>
			<CurrentVacanciesSection
				title={getTitle(data?.sanityCareersVacancies)}
				description={data?.sanityCareersVacancies?._rawDescription}
				locations={locations}
				teams={teams}
				//vacancies={data?.sanityCareersVacancies?.vacancies}
				vacancies={data?.sanityCareersVacancies?.bamboo_vacancies}
			/>
			<OurMissionSection
				sectionTitle={getTitle(data?.sanityCareersMission)}
				body={data?.sanityCareersMission?._rawDescription}
				bannerPosition='right'
				bannerImageUrl={data?.sanityCareersMission?.banner_image}
			/>
			<OurValuesSection
				title={data?.sanityCareersOurValues?.title}
				items={data?.sanityCareersOurValues?.values}
			/>
			<OurBenefitsSection
				title={getTitle(data?.sanityCareersOurBenefits)}
				benefits={data?.sanityCareersOurBenefits?.benefits}
			/>
			<BriefQAndASection
				title={getTitle(data?.sanityCareersQAndA)}
				qAndAAccordionData={data?.sanityCareersQAndA?.q_and_a}
			/>
			<OurSocialChannelSection
				title={data?.sanityCareersSocialChannel?.title}
				socialMedia={data?.sanityCareersSocialChannel?.social_media}
				channels={data?.sanityCareersSocialChannel?.channels}
			/>
		</Layout>
	);
};

export default CareersPage;

export const query = graphql`
	query {
		sanityCareersPageMetadata {
			careers_page_metadata {
				metadata {
					metaContent
					metaName
				}
				canonical
				pageMetaDescription
				pageMetaName
			}
		}
		sanityCareersBanner {
			background_image {
				asset {
					gatsbyImageData(width: 500, placeholder: BLURRED)
				}
			}
			careers_listing_banner {
				asset {
					gatsbyImageData(width: 500, placeholder: BLURRED)
				}
			}
			title
		}

		sanityCareersSummary {
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			_rawDescription
			banner_image {
				background_image_1 {
					asset {
						gatsbyImageData(width: 500, placeholder: BLURRED)
					}
				}
				background_image_2 {
					asset {
						gatsbyImageData(width: 500, placeholder: BLURRED)
					}
				}
				focus_image {
					asset {
						gatsbyImageData(width: 500, placeholder: BLURRED)
					}
				}
			}
		}

		sanityCareersMission {
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			_rawDescription
			banner_image {
				asset {
					gatsbyImageData(width: 500, placeholder: BLURRED)
				}
			}
		}

		sanityCareersOurValues {
			id
			title
			values {
				title
				icon {
					asset {
						gatsbyImageData(width: 60, placeholder: BLURRED)
					}
				}
				description
			}
		}

		sanityCareersOurBenefits {
			id
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			benefits {
				title
				description
			}
		}

		sanityCareersQAndA {
			id
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			q_and_a {
				question
				answer
			}
		}

		sanityCareersSocialChannel {
			id
			title
			social_media {
				youtube
				twitter
				tiktok
				linkedin
				instagram
				facebook
			}
			channels {
				channel_type
				channel_image {
					asset {
						gatsbyImageData(width: 500, placeholder: BLURRED)
					}
				}
			}
		}

		sanityCareersVacancies {
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			_rawDescription
			vacancies {
				id
				slug {
					current
				}
				title
				salary
				contract_type
				_rawBody
				apply_button {
					url
					title
				}
				vacancy_teams {
					id
					team_name
				}
				vacancy_location {
					vacancy_location
					vacancy_location_short
				}
			}
			bamboo_vacancies {
				id
				slug {
					current
				}
				title
				salary
				contract_type
				_rawBody
				apply_button {
					url
					title
				}
				vacancy_teams {
					id
					team_name
				}
				vacancy_location {
					vacancy_location
					vacancy_location_short
				}
			}
		}

		allSanityVacancyTeams {
			edges {
				node {
					id
					team_name
				}
			}
		}

		allSanityVacancyLocations {
			edges {
				node {
					id
					vacancy_location_short
					vacancy_location
				}
			}
		}
	}
`;
